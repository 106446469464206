<ion-app
  class="md container"
  [ngClass]="{
    'platform-core': isDesktop,
    'platform-mobile': !isDesktop
  }"
>
  <!-- #region Menu derecho del usuario -->
  <ion-menu
    *ngIf="globalVars?.user?.logged && isMobile"
    menuId="right-menu"
    contentId="main-content"
    type="push"
    class="right-menu"
    side="end"
    swipeGesture="false"
    [ngClass]="{ backgroundheader: globalVars.FEATURES?.MSO_NewReBranding }"
  >
    <ion-header class="codere-background">
      <ion-toolbar>
        <ion-buttons *ngIf="!globalVars?.user?.logged" class="loginOps align-right">
          <button ion-button class="btAccess" (click)="openLogin()">
            {{ 'access' | translate }}
          </button>
          <button ion-button class="btSignUp" (click)="openRegistro()">
            {{ 'signUp' | translate }}
          </button>
        </ion-buttons>

        <ion-buttons class="loginOps userMenu">
          <button ion-button light class="reload loguedUserName" (click)="refreshBalance()">
            <!-- [track-event]="['RefreshBalance', '', '', 'Refrescar saldo', 'event']" -->
            <p>{{ globalVars.user.username }}</p>
            <p>
              <i class="codere-icon icon-reload"></i>
              {{ globalVars?.user?.balance | parseMoney: [4] }}
            </p>
          </button>
          <button ion-button class="cta-close-btn" (click)="toggleSidebarMenu('right-menu')" tappable>
            <i class="codere-icon icon-close cta-close-icon"></i>
          </button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="bgLight">
      <ion-list class="menuUserList">
        <ion-list-header class="menu-user-list-header" tappable="true" (click)="openPageMenuUser($event)">
          <i class="codere-icon user-menu-item-icon icon-apuestas-83"></i>
          <span class="accordion-title">{{ 'uPHBet' | translate }}</span>
        </ion-list-header>

        <!-- Dropdown options -->
        <ion-accordion-group [multiple]="true" [animated]="false" (ionChange)="accordionGroupChange($event)">
          <ng-container *ngFor="let menu of itemsMenu$ | async; let i = index">
            <ion-accordion
              *ngIf="menu.items && menu.items?.length > 0"
              value="{{ menu.title }}"
              class="menu-dropdown-option"
            >
              <ion-item slot="header" color="light">
                <ion-icon
                  class="user-menu-item-icon"
                  [ngClass]="menu.icon"
                  *ngIf="menu.icon && menu.icon.startsWith('codere')"
                  slot="start"
                ></ion-icon>
                <img
                  [src]="menu.icon"
                  class="user-menu-item-icon"
                  *ngIf="menu.icon && !menu.icon.startsWith('codere')"
                />
                <ion-icon
                  class="ion-accordion-toggle-icon"
                  [name]="accordionIndexs.includes(i) ? 'remove-outline' : 'add-outline'"
                  slot="end"
                ></ion-icon>
                <ion-badge class="accordion-icon-badge" *ngIf="menu.badgeCount > 0" [class.alert-badge]="menu.isAlert">
                  {{ menu.badgeCount }}
                </ion-badge>
                <ion-label class="accordion-title">{{ menu.title }}</ion-label>
              </ion-item>
              <div slot="content">
                <ion-item
                  lines="none"
                  *ngFor="let int of menu.items"
                  tappable
                  (click)="itemMenuClick(int, 'right-menu', $event)"
                  [ngClass]="{ selected: sectionName === int?.section }"
                >
                  <div class="menu-dropdown-option-content">
                    <span>{{ int.label }}</span>
                    <ion-badge
                      class="menu-user--category-badge"
                      *ngIf="int.badgeCount > 0"
                      [class.alert-badge]="menu.isAlert"
                      >{{ int.badgeCount }}
                    </ion-badge>
                  </div>
                </ion-item>
              </div>
            </ion-accordion>
            <ion-item
              *ngIf="!menu.items || menu.items?.length === 0"
              tappable
              (click)="itemMenuClick(menu, 'right-menu', $event)"
              color="light"
              lines="none"
            >
              <ion-icon
                class="user-menu-item-icon"
                [ngClass]="menu.icon"
                *ngIf="menu.icon && menu.icon.startsWith('codere')"
                slot="start"
              ></ion-icon>
              <img [src]="menu.icon" class="user-menu-item-icon" *ngIf="menu.icon && !menu.icon.startsWith('codere')" />
              <ion-label class="accordion-title">{{ menu.title }}</ion-label>
            </ion-item>
          </ng-container>
        </ion-accordion-group>

        <!-- messages -->
        <ion-item-group
          class="menu-messages-logout menu-messages"
          *ngIf="globalVars.FEATURES?.messageServiceEnabled"
          (click)="openMessages()"
        >
          <i class="codere-icon icon-alert user-menu-item-icon">
            <ion-badge
              *ngIf="messageCount > 0 && globalVars.FEATURES?.messageServiceEnabled"
              class="accordion-icon-badge alert-badge"
            >
              {{ messageCount }}
            </ion-badge></i
          >
          <ion-label class="accordion-title">{{ 'tMessages' | translate }}</ion-label>
        </ion-item-group>
        <!-- logout -->
        <ion-item-group (click)="doLogout()" class="menu-messages-logout menu-logout">
          <i class="codere-icon icon-Off-On user-menu-item-icon"></i>
          <ion-label class="accordion-title">{{ 'tLogOut' | translate }}</ion-label>
        </ion-item-group>
      </ion-list>
    </ion-content>
    <ion-toolbar class="contactButton" [ngClass]="{ 'contact-button-up': hasSelections }">
      <button ion-button expand="block" (click)="openContact($event)">
        <i class="codere-icon icon-contact"></i>{{ 'tContac' | translate }}
      </button>
    </ion-toolbar>
  </ion-menu>
  <!-- #endregion -->
  <!-- #region Menu lateral izquierdo -->
  <ion-menu menuId="left-menu" contentId="main-content" type="push" class="left-menu">
    <ion-header class="sticky">
      <ion-toolbar
        menuClose
        tappable
        [color]="globalVars.FEATURES?.MSO_NewReBranding ? 'codereg' : 'codere'"
        [ngClass]="{
          backgroundheader: globalVars.FEATURES?.MSO_NewReBranding,
          colornewlogo: globalVars.FEATURES?.MSO_NewReBranding && !isCasinoUrl,
          colornewlogocas: globalVars.FEATURES?.MSO_NewReBranding && isCasinoUrl
        }"
      >
        <!-- <ion-searchbar [showCancelButton]="false"  placeholder="Buscar eventos, partidos..." disabled="true" [disabled]=true></ion-searchbar>-->
        <div class="vertical-center">
          <div class="closedcolor">
            <ion-button (click)="toggleSidebarMenu('left-menu')" class="cta-close-btn button-md">
              <i class="codere-icon icon-close cta-close-icon"></i>
            </ion-button>
          </div>
          <div class="cta-searchBar" (click)="toggleSidebarMenu('search-menu')">
            {{ 'v2_buscar' | translate }}
            <ion-icon name="search" style="margin-right: 0px"></ion-icon>
            <!--# Buscar eventos, partidos, juegos...-->
          </div>
        </div>
      </ion-toolbar>
    </ion-header>
    <div style="position: relative; overflow: scroll">
      <ion-list class="menuLatSections">
        <ion-item menuClose tappable *ngIf="false">
          <span class="ion-label codere-icon"
            ><img
              style="height: 23px; width: 23px; margin: 0 12px 0 0px"
              src="assets/global/img/Icono-Codere_Talent.png"
          /></span>

          <span>Codere Talent</span>
        </ion-item>
        <ion-item menuClose tappable class="item" (click)="openToday()">
          <i class="codere-icon icon-calendar"></i>
          <span class="ion-label">{{ 'textToday' | translate }}</span>
        </ion-item>
        <!--Inicio-->

        <ion-item menuClose tappable *ngFor="let lobby of lobbies" (click)="openLobby(lobby)">
          <ng-container *ngIf="lobby?.Icon !== '' && lobby?.Icon !== undefined">
            <span class="codere-icon">
              <img class="menu-left-thumbnail" [src]="lobby.Icon" />
            </span>
            <span class="ion-label casino-txt">{{ lobby.LobbyTitle | translate }}</span>
          </ng-container>
          <ng-container *ngIf="lobby?.Icon === '' || lobby?.Icon === undefined">
            <i
              class="codere-icon icon-slots"
              *ngIf="
                lobby.LobbyTitle !== 'Casino' &&
                lobby.LobbyTitle !== 'Casino en Vivo' &&
                lobby.LobbyTitle !== 'Aviator' &&
                lobby.LobbyTitle !== 'Scarab Cash' &&
                lobby.LobbyTitle !== 'LA VOZ'
              "
            ></i>
            <i class="codere-icon icon-casino" *ngIf="lobby.LobbyTitle === 'Casino'"></i>
            <i class="codere-icon icon-blackjack-crupier" *ngIf="lobby.LobbyTitle === 'Casino en Vivo'"></i>
            <span class="codere-icon" *ngIf="lobby.LobbyTitle === 'Aviator'">
              <img class="menu-left-thumbnail" src="assets/global/img/aviator.png" />
            </span>
            <span class="codere-icon" *ngIf="lobby.LobbyTitle === 'Scarab Cash'">
              <img class="menu-left-thumbnail" src="assets/global/img/ScarabCash.png" />
            </span>
            <span class="codere-icon" *ngIf="lobby.LobbyTitle === 'LA VOZ'">
              <img class="menu-left-thumbnail" src="assets/global/img/laVoz.png" />
            </span>
            <span class="ion-label casino-txt">{{ lobby.LobbyTitle | translate }}</span>
          </ng-container>
        </ion-item>

        <ion-item menuClose tappable (click)="openJackpots()" *ngIf="globalVars?.FEATURES?.JackpotLobbyEnabled">
          <i class="codere-icon icon-sub-slots"></i>
          <span class="ion-label casino-txt">Jackpots</span>
        </ion-item>

        <ion-item
          menuClose
          tappable
          (click)="openCalendarGames()"
          *ngIf="globalVars?.FEATURES?.CasinoCalendarEnabled && haveCalendarGames?.length > 0"
        >
          <span class="codere-icon">
            <img class="menu-left-thumbnail" src="assets/global/img/calendarNew.svg" />
          </span>
          <span class="ion-label casino-txt">{{ globalVars?.FEATURES?.casinoCalendarName }}</span>
        </ion-item>

        <ion-item menuClose tappable (click)="openPromotions()" *ngIf="globalVars?.FEATURES?.Promolink">
          <i class="codere-icon icon-promocion"></i>
          <span class="ion-label">{{ 'slotheaderPromotions' | translate }}</span>
        </ion-item>

        <ion-item menuClose tappable *ngIf="globalVars?.FEATURES?.videobingoEnabled">
          <span class="codere-icon"><img src="assets/global/img/headerHome/bingo.svg" alt="Bingo" /></span>
          <span class="ion-label">Video Bingo</span>
        </ion-item>
        <ion-item menuClose tappable *ngIf="globalVars?.FEATURES?.ourLocals">
          <a external-link href="https://bingos.codere.com.ar/" target="_blank" class="abutton">
            <span class="codere-icon"><i class="sb-navbar-item--icon codere-icon icon-slots"></i></span>
            <span class="ion-label">{{ 'ourbooks' | translate }}</span></a
          >
        </ion-item>
        <ion-item menuClose tappable (click)="openDirectos()">
          <i class="codere-icon icon-en-directo-stream"></i>
          <span class="ion-label">
            {{ 'nowLive' | translate }}
          </span>
          <!--Ahora en directo-->
        </ion-item>
        <ion-item menuClose tappable (click)="openLigaAguila()" *ngIf="globalVars?.FEATURES?.LigaAguilaEnabled">
          <span class="codere-icon"
            ><img src="assets/global/img/liga-aguila.svg" style="height: 26px; width: 26px; margin: 0 10px 0 2px"
          /></span>

          <span class="ion-label">Liga Águila</span>
        </ion-item>

        <ion-item
          *ngIf="!globalVars?.FEATURES?.SPO_BetbuilderEnabled"
          menuClose
          (click)="globalVars.rootScope.openBetbuilder()"
        >
          <img class="menu-left-thumbnail" src="assets/global/img/betbuilder/betbuilder-icon.svg" />
          <span class="ion-label">{{ 'SPO_CreaTuApuesta' | translate }}</span>
        </ion-item>

        <ion-item
          *ngIf="globalVars.FEATURES.SPO_CodereFantasyEnabled"
          menuClose
          tappable
          (click)="globalVars.rootScope.openCodereFantasy()"
        >
          <img class="menu-left-thumbnail" src="assets/global/img/icon-FantasyLeague.svg" />
          <span class="ion-label">{{ 'SPO_CodereFantasy' | translate }}</span>
        </ion-item>

        <ion-item
          *ngFor="let specialS of sidebarFacade.highlightsConfig; let i = index"
          menuClose
          tappable
          (click)="openSpecialSport(specialS, 'left-menu')"
        >
          <img class="menu-left-thumbnail" src="{{ specialS.icon }}" />
          <span class="ion-label">{{ specialS.sport }}</span>
        </ion-item>

        <ion-item
          *ngFor="let specialG of specialGames; let i = index"
          menuClose
          tappable
          (click)="openSpecialGame(specialG)"
        >
          <img class="menu-left-thumbnail" src="assets/global/img/{{ specialG.icon }}" />
          <span class="ion-label">{{ specialG.gameName }}</span>
        </ion-item>

        <ion-item
          menuClose
          tappable
          (click)="openSpecialTeam('Real Madrid')"
          *ngIf="globalVars?.FEATURES?.RealMadridEnabled"
        >
          <img class="menu-left-thumbnail club-logo codere-icon" src="assets/global/img/real_madrid.svg" />
          <span class="ion-label">Real Madrid</span>
        </ion-item>

        <ion-item
          menuClose
          tappable
          (click)="openSpecialTeam('River Plate')"
          *ngIf="globalVars?.FEATURES?.RiverPlateEnabled"
        >
          <img class="menu-left-thumbnail club-logo codere-icon" src="assets/global/img/river_plate.png" />
          <span class="ion-label">River Plate</span>
        </ion-item>
      </ion-list>
      <ion-list class="menuLatEventos" [hidden]="sidebarFacade?.sports?.length == 0" no-margin>
        <ion-list-header class="menu-section-header">
          <!--EVENTOS A-Z-->
          <h2 class="az-sport-title">{{ 'azSport' | translate }}</h2>
        </ion-list-header>
        <ion-item *ngFor="let s of sidebarFacade?.sports">
          <button ion-item menuClose (click)="openPage($event, s)">
            <i class="codere-icon icon-{{ s.SportHandle }}"></i>{{ s.Name }}
          </button>
        </ion-item>
      </ion-list>
      <div class="smallVersion">
        <span>{{ globalVars?.strVersion }}</span>
      </div>
    </div>
  </ion-menu>

  <ion-menu id="search-menu" menuId="search-menu" contentId="main-content" type="push" side="end" swipeGesture="false">
    <ion-header>
      <ion-toolbar menuClose tappable color="codere">
        <!-- <ion-searchbar [showCancelButton]="false"  placeholder="Buscar eventos, partidos..." disabled="true" [disabled]=true></ion-searchbar>-->
        <div class="vertical-center">
          <div>
            <ion-button (click)="toggleSidebarMenu('search-menu')" class="cta-close-btn button-md">
              <i class="codere-icon icon-arrow-left"></i>
            </ion-button>
          </div>
          <ion-searchbar
            class="search-menu-searchbar"
            show-clear-button="focus"
            debounce="500"
            placeholder="{{ 'v2_buscar' | translate }}"
            (ionInput)="getItemsSearch($event)"
            (ionCancel)="onCancel($event)"
          ></ion-searchbar>
        </div>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-list no-lines class="sugerencias" *ngIf="searchItems?.length == 0 && !iniciarBusqueda">
        <ion-list-header>
          <ion-label class="ion-text-center">{{ 'v2_sugBusq' | translate }}</ion-label>
        </ion-list-header>
        <!--Sugerencias de búsqueda-->
        <ion-item
          class="item"
          *ngFor="let specialS of sidebarFacade.highlightsConfig; let i = index"
          (click)="openSpecialSport(specialS, 'search-menu')"
        >
          <ion-label class="ion-text-center">
            {{ specialS.sport }}
          </ion-label>
        </ion-item>
      </ion-list>
      <ion-list class="search-items" *ngIf="searchItems?.length > 0">
        <ion-item *ngFor="let item of searchItems" class="bgLightGrey" (click)="onItemSearch_Click($event, item)">
          <ion-grid>
            <ion-row>
              <ion-col size="1">
                <ion-avatar item-left class="search-items--avatar">
                  <i [ngClass]="'icon-' + item.SportHandle" class="codere-icon"></i>
                </ion-avatar>
              </ion-col>
              <ion-col size="8">
                <span class="search-items--name">
                  {{ item.Name | truncateString: 28 }}
                </span>
              </ion-col>
              <ion-col size="3">
                <span class="search-items--date">
                  {{ item.StartDate | extractDate }}
                </span>
              </ion-col>
            </ion-row>
          </ion-grid>
          <!-- todo para ticket de juan pablo -->
          <!-- <ion-avatar item-left>
            <i [ngClass]="'icon-' + item.SportHandle" class="codere-icon"></i>
          </ion-avatar>
          <span>{{ item.Name | truncateString: 30 }}</span>
          <span>{{ item.StartDate | extractDate }}</span> -->
          <!-- <h3>{{ item.LeagueName }}</h3> -->
          <i item-right class="codere-icon arrow icon-arrow-right"></i>
        </ion-item>
      </ion-list>

      <!--Empty State-->
      <empty-state
        [class.is-mobile]="isMobile"
        [message]="'m_menu_noSearch'"
        [subMessage]="searchQuery"
        [imageSource]="imageSource"
        *ngIf="showEmptyState"
      ></empty-state>
    </ion-content>
  </ion-menu>
  <!-- #endregion -->
  <!-- #region Page router outlet -->
  <div class="ion-page" id="main-content">
    <ion-header *ngIf="isCompleted && !betslipUnlockerUrl()">
      <codere-navbar-pc
        *ngIf="isDesktop && globalVars.showNavbar"
        (navigateToTicket)="navigateToTicket()"
      ></codere-navbar-pc>
      <codere-navbar-pc-submenu
        *ngIf="isDesktop && globalVars?.user?.logged"
        class="codere-navbar-pc-submenue-zindex"
      ></codere-navbar-pc-submenu>
      <codere-navbar-pc-search *ngIf="isDesktop"></codere-navbar-pc-search>
      <codere-navbar *ngIf="!isDesktop && canShowNavbarInThisPage()"></codere-navbar>
    </ion-header>
    <ion-grid class="grid" *ngIf="isCompleted">
      <ion-row class="row" [ngClass]="{ 'margin-top-not-logged': !betslipUnlockerUrl() }">
        <ion-col class="col codere-sidebar-pc" *ngIf="isLeftPanel()">
          <codere-sidebar-pc
            *ngIf="isSportbook()"
            [sports]="sidebarFacade.sports"
            [highlights]="sidebarFacade.highlights"
            [highlightsConfig]="sidebarFacade.highlightsConfig"
          ></codere-sidebar-pc>
          <codere-user-sidebar *ngIf="sectionName === 'user' && isDesktop"></codere-user-sidebar>
        </ion-col>
        <ion-col class="col">
          <ion-content>
            <ion-router-outlet [animated]="showRouterAnimations" [animation]="customTransition"></ion-router-outlet>
          </ion-content>
        </ion-col>
        <ion-col class="col aside" *ngIf="isRightPanel()">
          <div
            id="video-player-ticket"
            class="background-color-dark"
            [class.streaming-desktop-slot]="globalVars?.videoPlayerWindow"
          ></div>
          <sportradar-widget
            *ngIf="sportService?.showSportradar$ | async"
            [statisticsId]="sportService?.eventStatisticsId$ | async"
            [isFormula1]="sportService?.isFormula1$ | async"
            [SPO_LMTFormula1]="globalVars.FEATURES.SPO_LMTFormula1"
          ></sportradar-widget>

          <sb-tabs
            [categories]="filtersType$ | async"
            [color]="'dark'"
            [selectedTab]="selectedFilterType$ | async"
            (selectTab)="changeTicketType($event.value)"
          ></sb-tabs>

          <my-bets-betslip
            [hidden]="getFilterStatus(0)"
            [keyboard]="keyboard$ | async"
            [showMyBets]="getFilterStatus(1)"
            [selectedFilter]="myBetsSelectedFilterType$ | async"
            (showMoreBets)="showMoreBets($event)"
          >
            <sb-filter
              [filters]="myBetsFiltersType$ | async"
              [selected]="myBetsSelectedFilterType$ | async"
              [switch]="true"
              (filter)="changeMyBetsType($event)"
            >
            </sb-filter>
          </my-bets-betslip>

          <ticket
            [hidden]="getFilterStatus(1)"
            [ticketState]="ticket$ | async"
            (ticketOddAcceptance)="newTicketFacade?.presentOddAcceptance()"
            [class.streaming-desktop-slot]="globalVars?.videoPlayerWindow"
          >
          </ticket>
        </ion-col>
      </ion-row>
    </ion-grid>
    <ticket-mobile *ngIf="isMobile && !isDesktop && !isNotSportsPage()" [ticketState]="ticket$ | async"></ticket-mobile>
    <quick-launch-games
      *ngIf="globalVars?.FEATURES?.miniRouletteEnabled && globalVars.user?.logged && isMobile"
      [isActive]="menuRightOpen"
    ></quick-launch-games>
  </div>

  <video-player *ngIf="globalVars?.user?.logged"></video-player>
  <iframe *ngIf="urlPlaytechIframe" [src]="urlPlaytechIframe" #playtechIframe class="playtechIframe"></iframe>
</ion-app>
